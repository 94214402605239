import styled from "@emotion/styled";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";
export const StyledBasicButtonComponent = styled.div`
  max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px;
  margin: auto;

  .button-wrapper {
    display: flex;
    justify-content: center;

    &.left {
      justify-content: flex-start;
    }

    &.center {
      justify-content: center;
    }

    &.right {
      justify-content: flex-end;
    }
  }
`;
